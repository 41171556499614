/**
 * @license
 * Copyright 2023 Ada School
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

export const config = {
  TZ_KEY: "ada-TZ",
  JWE_KEY: "ada-JWE",
  JWE_EXPIRATION_KEY: "ada-JWE-EXP",
  RT_KEY_JWE: "ada-RT-JWE",
  EDIT_MODE: "ada-EditMode",
  GRAPHQL_URL:
    import.meta.env.VITE_GRAPHQL_URL || "http://localhost:4000/graphql",
  AUTH_API_URL:
    import.meta.env.VITE_AUTH_API_URL || "http://localhost:4000/v1/auth",
  APP_VERSION: import.meta.env.VITE_VERSION || "1",
  FT_KEY: "ada-FT",
  VITE_APP_ENV: import.meta.env.VITE_APP_ENV || "development",
  VITE_ADA_ENV: import.meta.env.VITE_ADA_ENV || "main",
  USE_LOCAL_AVATARS: import.meta.env.VITE_USE_LOCAL_AVATARS || false,
  PUBLIC_URL: import.meta.env.PUBLIC_URL || "",
  APP_COMMIT_REF: import.meta.env.VITE_COMMIT_REF || "",
  LANGUAGE_KEY: "ada-LNG",
};
