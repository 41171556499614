/**
 * @license
 * Copyright 2023 Ada School
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import type { Dict } from "@chakra-ui/utils";
import React, { Dispatch, SetStateAction, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { config } from "../config";
import { useDebouncedValue } from "../hooks/useDebouncedValue";
import { useLocalStorage } from "../hooks/useLocalStorage";
import {
  EntityName,
  School,
  User,
  UserProfile,
  UserRole,
} from "../schemaTypes";
import { defaultTheme } from "../theme";
import { FetchResult, useMutation } from "@apollo/client";
import {
  TrackEventDocument,
  TrackEventMutation,
} from "./graphql/trackEvent.generated";
type AvatarState = Pick<UserProfile, "avatarImageURL"> | null | undefined;

export type UserState =
  | (Pick<
      User,
      | "id"
      | "name"
      | "avatarUrl"
      | "roles"
      | "gitHubUsername"
      | "uploadedAvatarUrl"
    > & {
      profile?: AvatarState;
    })
  | null;

type SchoolState = Pick<
  School,
  | "id"
  | "name"
  | "logoUrl"
  | "linkedInId"
  | "features"
  | "frontendFeatures"
  | "heroImageUrl"
  | "preferredLanguage"
  | "allowedLanguages"
  | "faviconUrl"
  | "loginLogoUrl"
  | "heroBackground"
  | "privacyUrl"
  | "conductCodeUrl"
  | "termsUrl"
  | "headerColor"
  | "headerAccent"
  | "gradingPolicyUrl"
  | "onboardingFlowId"
> | null;

export type UserHook = {
  user: UserState;
  isEditModeEnabled: boolean;
  theme: Dict;
  setUser: Dispatch<SetStateAction<UserState>>;
  setTheme: Dispatch<SetStateAction<Dict>>;
  currentSchool: SchoolState;
  setCurrentSchool: Dispatch<SchoolState>;
  setUserCoins: Dispatch<SetStateAction<number>>;
  userCoins: number;
  userLevel: number;
  setUserLevel: Dispatch<SetStateAction<number>>;
  setUserExperience: Dispatch<SetStateAction<number>>;
  userExperience: number;
  setIsEditModeEnabled: Dispatch<SetStateAction<boolean>>;
  isAdmin?: boolean;
  timezone: string;
  setTimezone: (timezone: string) => void;
  hasRoles: (roles: Array<UserRole>) => boolean;
  isMenuVisible?: boolean;
  setIsMenuVisible: Dispatch<SetStateAction<boolean>>;
  recentCoursesIds: Array<string>;
  addRecentCourse: (courseId: string) => void;
  refetchUserStats: (delay?: number) => void;
  isUnlockedForUser: (requiredLevel: number) => boolean;
  lastUserStatsUpdate: number | undefined;
  scrollContainerRef: React.RefObject<HTMLDivElement>;
  trackEvent: ({
    event,
    cohortId,
    context,
    entity,
    entityId,
  }: {
    event: string;
    cohortId?: string;
    entity?: string;
    entityId?: EntityName | string;
    context?: Record<string, string>;
  }) => Promise<FetchResult<TrackEventMutation>>;
};

export const UserContext = React.createContext<UserHook | null>(null);

const RECENT_COURSES_KEY = "ada-RecentCourses";

export const UserProvider: React.FC<{ children?: React.ReactNode }> = (
  props
) => {
  const { i18n } = useTranslation();
  const [storedEditMode, setStoredEditMode] = useLocalStorage(
    config.EDIT_MODE,
    false
  );
  const [storedTimezone, setStoredTimezone] = useLocalStorage(
    config.TZ_KEY,
    "local"
  );
  const [user, setUser] = React.useState<UserState>(null);
  const [theme, setTheme] = React.useState<Dict>(defaultTheme);
  const [currentSchool, setCurrentSchool] = React.useState<SchoolState>(null);
  const [storeRecentCoursesIds, setStoreRecentCoursesIds] = useLocalStorage<
    Array<string>
  >(RECENT_COURSES_KEY, []);
  const [recentCoursesIds, setRecentCoursesIds] = useState<Array<string>>(
    storeRecentCoursesIds
  );
  const [isMenuVisible, setIsMenuVisible] = React.useState<boolean>(true);
  const [isEditModeEnabled, setIsEditModeEnabled] =
    React.useState<boolean>(storedEditMode);
  const [timezone, setTimezone] = React.useState<string>(
    storedTimezone || "local"
  );
  const [lastUserStatsUpdateValue, setLastUserStatsUpdateValue] = useState<
    number | undefined
  >(undefined);
  const lastUserStatsUpdate = useDebouncedValue(lastUserStatsUpdateValue, 500);
  const [userCoins, setUserCoins] = useState(0);
  const [userLevel, setUserLevel] = useState(0);
  const [userExperience, setUserExperience] = useState(0);
  const isAdmin = user?.roles.includes(UserRole.Admin);
  const hasRoles = (roles: Array<UserRole>) =>
    roles.some((role) => user?.roles.includes(role));
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const [trackEvent] = useMutation(TrackEventDocument, {
    errorPolicy: "all",
  });

  const value = React.useMemo<UserHook>(
    () => ({
      user,
      timezone,
      setTimezone: (val: string) => {
        setStoredTimezone(val);
        return setTimezone(val);
      },
      setUser,
      setUserCoins,
      userCoins,
      userExperience,
      setUserExperience,
      setUserLevel,
      userLevel,
      isAdmin,
      hasRoles,
      isEditModeEnabled,
      setIsEditModeEnabled: (val: SetStateAction<boolean>) => {
        setStoredEditMode(val === true);
        return setIsEditModeEnabled(val);
      },
      trackEvent: ({ event, cohortId, entity, entityId, context }) => {
        const sourceURL = window.location.href;
        return trackEvent({
          variables: {
            trackEventInput: {
              event,
              cohortId,
              sourceURL,
              context,
              entity,
              entityId,
            },
          },
        });
      },
      isMenuVisible,
      setIsMenuVisible,
      recentCoursesIds,
      addRecentCourse: (courseId: string) => {
        const firstUniqueIds = Array.from(
          new Set([courseId, ...storeRecentCoursesIds])
        )
          .slice(0, 5)
          .sort((leftCourseId, rightCourseId) =>
            leftCourseId === courseId ? -1 : rightCourseId === courseId ? 1 : 0
          );
        setRecentCoursesIds(firstUniqueIds);
        setStoreRecentCoursesIds(firstUniqueIds);
      },
      lastUserStatsUpdate,
      refetchUserStats: (delay = 0) => {
        setTimeout(() => {
          setLastUserStatsUpdateValue(Date.now());
        }, delay);
      },
      isUnlockedForUser: (requiredLevel: number) => {
        return userLevel >= requiredLevel;
      },
      currentSchool,
      setCurrentSchool: (val: SchoolState) => {
        if (
          val &&
          (val.preferredLanguage || val.allowedLanguages?.length === 1)
        ) {
          const defaultLanguage =
            val.allowedLanguages?.[0] ?? val.preferredLanguage ?? "en";
          i18n.changeLanguage(defaultLanguage);
        }
        return setCurrentSchool(val);
      },
      setTheme,
      theme,
      scrollContainerRef,
    }),
    [
      user,
      currentSchool,
      theme,
      isEditModeEnabled,
      timezone,
      isMenuVisible,
      recentCoursesIds,
      lastUserStatsUpdate,
      userCoins,
      userLevel,
      userExperience,
    ]
  );
  return <UserContext.Provider value={value} {...props} />;
};
