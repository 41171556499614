/**
 * @license
 * Copyright 2023 Ada School
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import "./unregister-sw";

import { ApolloProvider } from "@apollo/client";
import React, { Suspense } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { createRoot } from "react-dom/client";
import { I18nextProvider } from "react-i18next";
import { BrowserRouter } from "react-router-dom";
import { ErrorBoundary } from "./ErrorBoundary";
import { ThemeSwitcher } from "./ThemeSwitcher";
import { apolloClient } from "./apolloClient";
import i18next from "./i18n";
import "./index.css";
import { UserProvider } from "./providers/UserProvider";

const container = document.getElementById("root");
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);

root.render(
  <React.StrictMode>
    <ErrorBoundary>
      <Suspense fallback={null}>
        <BrowserRouter>
          <I18nextProvider i18n={i18next}>
            <ApolloProvider client={apolloClient}>
              <UserProvider>
                <DndProvider backend={HTML5Backend}>
                  <ThemeSwitcher />
                </DndProvider>
              </UserProvider>
            </ApolloProvider>
          </I18nextProvider>
        </BrowserRouter>
      </Suspense>
    </ErrorBoundary>
  </React.StrictMode>
);
