import React from "react";
import { UserContext, UserHook } from "./UserProvider";

export const useUser = (): UserHook => {
  const context = React.useContext(UserContext);
  if (!context) {
    throw new Error(`useUser must be used within a UserContext`);
  }
  return context;
};
