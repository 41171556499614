/**
 * @license
 * Copyright 2023 Ada School
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { useEffect, useRef, useState } from "react";

export const useDebouncedValue = <T>(value: T, delay = 600): T => {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);
  const isMounted = useRef(false);

  useEffect(() => {
    if (isMounted.current && value !== debouncedValue) {
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      return () => {
        clearTimeout(handler);
      };
    }
  }, [value]);

  useEffect(() => {
    isMounted.current = true;
  }, []);

  return debouncedValue;
};
