import * as Types from '../../schemaTypes';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type TrackEventMutationVariables = Types.Exact<{
  trackEventInput: Types.TrackEventInput;
}>;


export type TrackEventMutation = { __typename?: 'Mutation', track: string };


export const TrackEventDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"trackEvent"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"trackEventInput"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"TrackEventInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"track"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"trackEventInput"},"value":{"kind":"Variable","name":{"kind":"Name","value":"trackEventInput"}}}]}]}}]} as unknown as DocumentNode<TrackEventMutation, TrackEventMutationVariables>;