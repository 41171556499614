/**
 * @license
 * Copyright 2024 Ada School
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { TranslatedField } from "../schemaTypes";

type CustomErrorOptions = {
  status?: number;
  message?: string;
  translations?: TranslatedField[];
};

export class ErrorWithTranslations extends Error {
  status: number;

  translations: TranslatedField[];

  constructor({ status, message, translations }: CustomErrorOptions) {
    super(message);
    this.message = message ?? "Internal Server Error";
    this.status = status ?? 500;
    this.translations = translations ?? [];
  }
}
