/**
 * @license
 * Copyright 2023 Ada School
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { datadogRum } from "@datadog/browser-rum";
import React, { ErrorInfo } from "react";

interface ErrorBoundaryProps {
  message?: string;
  onError?: (error: Error) => void;
  children?: React.ReactNode;
}

interface ErrorBoundaryState {
  error?: Error;
}

export class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { error: undefined };
  }

  static getDerivedStateFromError(error: Error) {
    return { error };
  }

  componentDidCatch(error: Error, info: ErrorInfo) {
    this.props.onError?.(error);
    if (process.env.NODE_ENV === "production" && error) {
      datadogRum.addError(error, { source: "ErrorBoundary", info });
    }
  }

  render() {
    if (this.state.error) {
      return (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
            width: "100%",
            textAlign: "center",
          }}
        >
          <div
            style={{
              border: "solid 1px red",
              padding: 8,
              backgroundColor: "#ff8888",
              borderRadius: 4,
              width: 400,
            }}
          >
            {this.props.message ? this.props.message : null}
            {this.state.error.message ? (
              <div
                style={{
                  borderRadius: "4px",
                  fontFamily: "monospace",
                  fontSize: "0.9rem",
                  color: "white",
                }}
              >
                {this.state.error.message}
              </div>
            ) : null}
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}
