/**
 * @license
 * Copyright 2023 Ada School
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { extendTheme } from "@chakra-ui/react";
import { Dict } from "@chakra-ui/utils";

export const defaultTheme = extendTheme({
  initialColorMode: "light",
  useSystemColorMode: true,
  fonts: {
    heading: "Quicksand",
    body: "Quicksand",
  },
  colors: {
    primary: {
      50: "#F2F2F2",
      100: "#DBDBDB",
      200: "#C4C4C4",
      300: "#ADADAD",
      400: "#969696",
      500: "#808080",
      600: "#666666",
      700: "#4D4D4D",
      800: "#333333",
      900: "#1A1A1A",
    },
    secondary: {
      50: "#E7F1FD",
      100: "#BCD8FB",
      200: "#91BFF8",
      300: "#65A6F5",
      400: "#3A8DF3",
      500: "#0F73F0",
      600: "#0C5CC0",
      700: "#094590",
      800: "#062E60",
      900: "#031730",
    },
    tertiary: {
      50: "#F2F2F2",
      100: "#DBDBDB",
      200: "#C4C4C4",
      300: "#ADADAD",
      400: "#969696",
      500: "#808080",
      600: "#666666",
      700: "#4D4D4D",
      800: "#333333",
      900: "#1A1A1A",
    },
    light: {
      50: "#F2F2F2",
      100: "#DBDBDB",
      200: "#C4C4C4",
      300: "#ADADAD",
      400: "#969696",
      500: "#808080",
      600: "#666666",
      700: "#4D4D4D",
      800: "#333333",
      900: "#1A1A1A",
    },
    dark: {
      50: "#F2F2F2",
      100: "#DBDBDB",
      200: "#C4C4C4",
      300: "#ADADAD",
      400: "#969696",
      500: "#808080",
      600: "#666666",
      700: "#4D4D4D",
      800: "#333333",
      900: "#1A1A1A",
    },
  },
});

export const mergeThemeWithDefaults = (theme: Dict): Dict =>
  extendTheme(theme, defaultTheme);
