/**
 * @license
 * Copyright 2023 Ada School
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { ApolloLink } from "@apollo/client";
import { config } from "./config";

export type BrowserCacheVariables = { bypassCache?: boolean };

const queriesToBypassCache: Set<string> = new Set<string>();

export const browserCacheLink = new ApolloLink((operation, forward) => {
  const context = operation.getContext();

  if (context.nextBypassQueries && Array.isArray(context.nextBypassQueries)) {
    for (const query of context.nextBypassQueries) {
      queriesToBypassCache.add(query);
    }
  }

  const noCacheVariable = !!operation.variables.bypassCache;
  const isEditModeEnabled = localStorage.getItem(config.EDIT_MODE) === "true";
  const isNoCacheModeEnabled =
    localStorage.getItem(config.FT_KEY) === "no-cache";
  const noCacheHeader =
    isEditModeEnabled ||
    isNoCacheModeEnabled ||
    noCacheVariable ||
    queriesToBypassCache.has(operation.operationName)
      ? { "cache-control": "no-cache", pragma: "no-cache" }
      : {};

  if (queriesToBypassCache.has(operation.operationName)) {
    queriesToBypassCache.delete(operation.operationName);
  }

  operation.setContext({
    ...context,
    headers: {
      ...context.headers,
      ...noCacheHeader,
    },
  });

  return forward(operation);
});
