/**
 * @license
 * Copyright 2023 Ada School
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

const omitTypename = (key: string, value: unknown) =>
  key === "__typename" ? undefined : value;

export const omitTypenameDeep = (
  variables: Record<string, unknown>
): Record<string, unknown> =>
  JSON.parse(JSON.stringify(variables), omitTypename);
