/**
 * @license
 * Copyright 2023 Ada School
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

export const ADA_ERROR_EVENT = "Ada:ErrorEvent";

export class AdaErrorEvent extends CustomEvent<{ message: string }> {
  message: string;

  constructor(message: string) {
    super(ADA_ERROR_EVENT);
    this.message = message;
  }
}
